var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn",attrs:{"outlined":"","height":"80","block":""}},'v-btn',attrs,false),on),[_vm._v(" Pacientes "),_c('v-icon',{staticClass:"ma-3",attrs:{"small":"","color":"white","size":"60"}},[_vm._v(" mdi-account-heart ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-8 ma-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-icon',{on:{"click":function($event){_vm.dialog=false}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-title',[_vm._v(" Pacientes ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.filtro,"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-radio-group',{attrs:{"row":"","label":"Regulados"},on:{"change":function($event){return _vm.filtrar()}},model:{value:(_vm.orientacao),callback:function ($$v) {_vm.orientacao=$$v},expression:"orientacao"}},[_c('v-radio',{attrs:{"value":true,"label":"SIM"}}),_c('v-radio',{attrs:{"value":false,"label":"NÃO"}}),_c('v-radio',{attrs:{"value":null,"label":"N/A"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.dt_nascimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcularIdade(item.dt_nascimento))+" ")]}},{key:"item.orientar",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sim_nao(item.orientar))+" ")]}},{key:"item.ver",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("mutirao-sus-printer/" + (item.id)),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }